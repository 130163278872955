.App {
  display: flex;
  height: 100%;
}

.LeftDrawer {
  height: 100%;
  width: 75px;
  min-width: 75px;
  background-color: #eee;
  display: flex;
  flex-direction: column;
}

.View {
  display: "flex";
  flex: 1;
}

@mixin IconLink {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 26px;
  text-decoration: none;

  &:visited {
    color: #000;
  }

  &:hover {
    background-color: #fff;
  }
}

.LeftDrawerIconLink {
  @include IconLink;
}

.LeftDrawerIconLinkActive {
  @include IconLink;
  background-color: #fff;
}
