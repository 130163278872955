.Exchange {
    display: flex;
    flex-direction: column;
}

.Order {}

.InlineLabel {
    display: flex;
    flex-direction: row;
}

.Button {
    cursor: pointer;
    background-color: none;
    display: flex;
    align-items: center;

    i:active {
        opacity: 0.6;
    }
}

.LoadingIcon {
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
